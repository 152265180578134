import React from "react";
import SelectedStoreSales from "./SelectedStoreSales";

const StoreInsight: React.FC = () => {
    return (
        <SelectedStoreSales />
    );
};

export default StoreInsight;
