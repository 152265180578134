import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk, createAppAsyncThunk } from "appThunk";
import { backdropOff, backdropOn } from "modules/backdrop/backdropSlice";
import { setupCube } from "modules/helpers/cube/cubeSlice";
import { logError } from "modules/helpers/logger/loggerSlice";
import { notifyError } from "modules/notifications/notificationsSlice";
import { RootState } from "store";
import { loadPartners, Partner } from "./partner";
import { clearPartnerFilters } from "./partnerFilters/partnerFiltersSlice";
import { loadReferenceDate } from "./referenceDate";
import { loadFilterStores, loadStores, Store } from "./store";
import { DateTime } from "luxon";
import { DataWrapper } from "domain/dataWrapper";
import { resetStoreProductFilters } from "./storeProductFilters/storeProductFiltersSlice";

export enum ProductChapter {
    ProductOverview = 1,
    ProductOpportunities,
    StoreOverview,
    StoreOpportunities,
    ProductStoreFit
}

interface LoadProductResponse {
    partners: Partner[],
    referenceDate: DateTime
}

interface ProductState {
    isLoading: boolean,
    hasErrors: boolean,
    currentChapter: ProductChapter,
    partners: Partner[],
    referenceDate: DateTime,
    stores: DataWrapper<Store[]>,
    filterStores: DataWrapper<Store[]>,
    selectedPartner?: Partner,
    selectedStore?: Store
}

const initialState: ProductState = {
    isLoading: false,
    hasErrors: false,
    currentChapter: ProductChapter.StoreOverview,
    partners: [],
    referenceDate: DateTime.fromMillis(0),
    stores: { isLoading: false, hasErrors: false, data: [] },
    filterStores: { isLoading: false, hasErrors: false, data: [] },
    selectedPartner: undefined,
    selectedStore: undefined
};

const productSlice = createSlice({
    name: "customer/tools/product",
    initialState,
    reducers: {
        setCurrentChapter: (state, action: PayloadAction<ProductChapter>) => {
            state.currentChapter = action.payload;
        },
        resetCurrentChapter: (state) => {
            state.currentChapter = initialState.currentChapter;
        },
        choosePartner: (state, action: PayloadAction<Partner>) => {
            state.selectedPartner = action.payload;
        },
        chooseStore: (state, action: PayloadAction<Store>) => {
            state.selectedStore = action.payload;
        },
        clearPartners: (state) => {
            state.partners = initialState.partners;
        },
        clearSelectedPartner: (state) => {
            state.selectedPartner = initialState.selectedPartner;
        },
        clearReferenceDate: (state) => {
            state.referenceDate = initialState.referenceDate;
        },
        clearStores: (state) => {
            state.stores = initialState.stores;
        },
        clearFilterStores: (state) => {
            state.filterStores = initialState.filterStores;
        },
        clearSelectedStore: (state) => {
            state.selectedStore = initialState.selectedStore;
        },
    },
    extraReducers: (builder: any) => {
        builder.addCase(loadProduct.pending, (state: ProductState) => {
            state.isLoading = true;
            state.hasErrors = false;
            state.partners = initialState.partners;
            state.referenceDate = initialState.referenceDate;
        });
        builder.addCase(loadProduct.rejected, (state: ProductState) => {
            state.isLoading = false;
            state.hasErrors = true;
            state.partners = initialState.partners;
            state.referenceDate = initialState.referenceDate;
        });
        builder.addCase(loadProduct.fulfilled, (state: ProductState, action: PayloadAction<LoadProductResponse>) => {
            state.isLoading = false;
            state.hasErrors = false;
            state.partners = action.payload.partners;
            state.referenceDate = action.payload.referenceDate;
        });
        builder.addCase(loadStores.pending, (state: ProductState) => {
            state.stores.isLoading = true;
            state.stores.hasErrors = false;
            state.stores.data = [];
        });
        builder.addCase(loadStores.rejected, (state: ProductState) => {
            state.stores.isLoading = false;
            state.stores.hasErrors = true;
            state.stores.data = [];
        });
        builder.addCase(loadStores.fulfilled, (state: ProductState, action: PayloadAction<Store[]>) => {
            state.stores.data = action.payload;
            state.stores.isLoading = false;
            state.stores.hasErrors = false;
        });
        builder.addCase(loadFilterStores.pending, (state: ProductState) => {
            state.filterStores.isLoading = true;
            state.filterStores.hasErrors = false;
            state.filterStores.data = [];
        });
        builder.addCase(loadFilterStores.rejected, (state: ProductState) => {
            state.filterStores.isLoading = false;
            state.filterStores.hasErrors = true;
            state.filterStores.data = [];
        });
        builder.addCase(loadFilterStores.fulfilled, (state: ProductState, action: PayloadAction<Store[]>) => {
            state.filterStores.data = action.payload;
            state.filterStores.isLoading = false;
            state.filterStores.hasErrors = false;
        });
    }
});

export const {
    setCurrentChapter,
    choosePartner,
    chooseStore,
    clearSelectedStore
} = productSlice.actions;

export const loadProduct = createAppAsyncThunk(
    "customer/tools/location/loadProduct",
    async (arg, thunkAPI) => {
        thunkAPI.dispatch(backdropOn());
        try {
            await thunkAPI.dispatch(setupCube());
            const referenceDate = await thunkAPI.dispatch(loadReferenceDate());
            const partners = await thunkAPI.dispatch(loadPartners(referenceDate));
            const loadProductResponse: LoadProductResponse = {
                partners,
                referenceDate
            };
            return loadProductResponse;
        } catch (error) {
            thunkAPI.dispatch(notifyError("Error loading Product."));
            return thunkAPI.rejectWithValue(null);
        } finally {
            thunkAPI.dispatch(backdropOff());
        }
    }
);

export const clearProduct = (): AppThunk => async (dispatch) => {
    dispatch(productSlice.actions.resetCurrentChapter());
    dispatch(productSlice.actions.clearPartners());
    dispatch(productSlice.actions.clearReferenceDate());
    dispatch(productSlice.actions.clearSelectedPartner());
    dispatch(clearPartnerFilters());
    dispatch(clearInsights());
};

export const loadInsights = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const partner = selectSelectedPartner(state);
        const referenceDate = selectReferenceDate(state);
        const selectedStore = selectSelectedStore(state);
        dispatch(loadStores({ referenceDate, partner, selectedStore }));
    } catch (error) {
        dispatch(logError("Error loading Insights.", error));
    }
};

export const loadStoreFilters = (): AppThunk => async (dispatch, getState) => {
    try {
        const state = getState();
        const partner = selectSelectedPartner(state);
        const referenceDate = selectReferenceDate(state);
        dispatch(loadFilterStores({ referenceDate, partner }));
    } catch (error) {
        dispatch(logError("Error loading Store Filters.", error));
    }
};

export const clearInsights = (): AppThunk => (dispatch) => {
    dispatch(productSlice.actions.clearStores());
};

export const clearStoreProductFilters = (): AppThunk => (dispatch) => {
    dispatch(productSlice.actions.clearSelectedStore());
    dispatch(productSlice.actions.clearFilterStores());
    dispatch(resetStoreProductFilters());
};

export const selectIsLoading = (state: RootState) => {
    return state.customer.tools.product.root.isLoading;
};

export const selectHasErrors = (state: RootState) => {
    return state.customer.tools.product.root.hasErrors;
};

export const selectCurrentChapter = (state: RootState) => {
    return state.customer.tools.product.root.currentChapter;
};

export const selectPartners = (state: RootState) => {
    return state.customer.tools.product.root.partners;
};

export const selectReferenceDate = (state: RootState) => {
    return state.customer.tools.product.root.referenceDate;
};

export const selectStores = (state: RootState) => {
    return state.customer.tools.product.root.stores;
};

export const selectFilterStores = (state: RootState) => {
    return state.customer.tools.product.root.filterStores;
};

export const selectSelectedPartner = (state: RootState) => {
    return state.customer.tools.product.root.selectedPartner;
};

export const selectSelectedStore = (state: RootState) => {
    return state.customer.tools.product.root.selectedStore;
};

export const selectStoresTotalSales = createSelector(
    selectStores,
    (stores) => {
        const totalOptimisedSales = stores.data.reduce((total, current) => total + current.sales.optimisedSales, 0);
        const totalEstimatedSales = stores.data.reduce((total, current) => total + current.sales.estimatedSales, 0);
        const totalClientSourcedSales = stores.data.reduce((total, current) => total + current.sales.clientSourcedSales, 0);
        const totalSalesHeadroom = stores.data.map(store => store.sales.salesHeadroom)
            .filter(headroom => headroom > 0)
            .reduce((total, current) => total + current, 0);

        return {
            isLoading: stores.isLoading,
            hasErrors: stores.hasErrors,
            data: {
                totalOptimisedSales,
                totalEstimatedSales,
                totalClientSourcedSales,
                totalSalesHeadroom
            }
        };
    }
);

export const selectFlattenedStores = createSelector(
    selectStores,
    (stores) => {
        return {
            isLoading: stores.isLoading,
            hasErrors: stores.hasErrors,
            data: stores.data.map(store => ({
                ...store,
                ...store.sales
            }))
        };
    }
);

export default productSlice;
