import React from "react";
import { Box, Typography } from "@material-ui/core";

import { useAppSelector } from "store";
import { selectSelectedPartner, selectSelectedStore } from "modules/customer/tools/product/productSlice";

import KPIComparison from "components/visuals/KPIComparison";
import numberFormatter from "utils/numberFormatter";

const SelectedStoreSales = () => {
    const selectedPartner = useAppSelector(selectSelectedPartner);
    const selectedStore = useAppSelector(selectSelectedStore);
    const label = `${selectedPartner?.name} headroom value ${selectedStore?.name}`;

    return (
        <Box width="100%" height="100%" data-cy="selected-store-total-headroom">
            <KPIComparison
                isLoading={false}
                hasErrors={false}
                targetValue={
                    <Typography variant="h3" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(selectedStore?.sales.salesHeadroom ?? 0, 1)}
                    </Typography>
                }
                targetLabel={label}
                comparisonValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(selectedStore?.sales.optimisedSales ?? 0, 1)}
                    </Typography>
                }
                comparisonLabel="Optimised sales in the past 12 months"
                differenceValue={
                    <Typography variant="h6" component="div" style={{ color: "#FFFFFF" }}>
                        {numberFormatter.toGBP(selectedStore?.sales.estimatedSales ?? 0, 1)}
                    </Typography>
                }
                differenceLabel="Estimated sales in the past 12 months"
            />
        </Box>
    );
};

export default SelectedStoreSales;
