export class ProductSales {
    public readonly id: string;
    public readonly name: string;
    public readonly clientSourcedSales: number;
    public readonly estimatedSales: number;
    public readonly optimisedSales: number;
    public readonly salesHeadroom: number;
    public readonly confidenceInterval?: number;

    constructor(
        id: string,
        name: string,
        clientSourcedSales: number,
        estimatedSales: number,
        optimisedSales: number,
        salesHeadroom: number,
        confidenceInterval?: number
    ) {
        this.id = id;
        this.name = name;
        this.clientSourcedSales = clientSourcedSales;
        this.estimatedSales = estimatedSales;
        this.optimisedSales = optimisedSales;
        this.salesHeadroom = salesHeadroom;
        this.confidenceInterval = confidenceInterval;
    }
}
